import { cn } from '@/lib/utils';
import LeadGenerationOpenForm from '@components/LeadGenerationForm/LeadGenerationOpenForm';
import React, { useEffect, useState } from 'react';
import {
  DetailsBanner,
  GreenBanner,
  GreenBannerV2,
} from '@components/v5/FspStylePageHero';
import RawHTML from '@components/Common/RawHTML/index';

const CustomHeroSection = ({ data, props }) => {
  console.log('🚀 ~ CustomHeroSection ~ data:', data);
  const [navbarHeight, setNavbarHeight] = useState();

  useEffect(() => {
    const navbarHeight =
      document.getElementById('navbar-wrapper')?.offsetHeight;

    setNavbarHeight(navbarHeight);
  }, []);

  const {
    pageUrl,
    heroStyles,
    isInfoCardVisible,
    firstFold,
    secondFold,
    sectionWrapperStyles,
    sectionOneStyles,
    sectionTwoStyles,
    formStyles,
  } = data || {
    slug: 'default',
    pageUrl: 'FELLOWSHIP',
    heroStyles: null,
    sectionWrapperStyles: null,
    sectionOneStyles: null,
    sectionTwoStyles: null,
    formStyles: null,
    isInfoCardVisible: true,
    secondFold:
      '<p className="text-[14px] md:text-[20px]"> <strong>Rapid technological growth</strong> and need for innovative software has increased the demand for <strong>skilled software developers</strong>. <br className="hidden md:block" /> <br /> Gain essential skills and build <strong>real-world projects</strong> to tackle modern challenges. </p>',
    firstFold:
      '<h1 class="text-center font-manrope text-[28px] font-bold lg:text-left md:text-[48px]">Fellowship Program in Software Development</h1>',
    createdAt: '2024-10-27T17:04:23.525Z',
    updatedAt: '2024-10-28T04:07:09.072Z',
    publishedAt: '2024-10-27T17:04:24.681Z',
  };

  const shouldShowAffiliatePagePopup = props?.shouldShowAffiliatePagePopup;

  return (
    <div
      className={cn(`text-black`, heroStyles)}
      style={{ paddingTop: navbarHeight ? `${navbarHeight}px` : '126px' }}
    >
      <div
        className={cn(
          'container flex flex-col gap-3 py-6 md:px-0 md:py-10 lg:flex-row lg:gap-0',
          sectionWrapperStyles,
        )}
      >
        <div
          className={cn(
            'lg:justify-left flex h-full flex-1 flex-col items-center justify-center gap-4 lg:items-start',
            sectionOneStyles,
          )}
        >
          <RawHTML>{firstFold}</RawHTML>
          <RawHTML>{secondFold}</RawHTML>
          {isInfoCardVisible ? (
            <div className="flex flex-col gap-3 md:flex-row">
              {props?.disableInfoLabelOne ? (
                <div className="mt-8">
                  <GreenBannerV2 {...props} />
                </div>
              ) : (
                <GreenBanner {...props}></GreenBanner>
              )}
              {!props?.disableInfoLabelOne ? (
                <DetailsBanner
                  {...props}
                  className="hidden md:block"
                ></DetailsBanner>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          className={cn(
            'flex h-full flex-1 items-center justify-center',
            sectionTwoStyles,
          )}
        >
          <LeadGenerationOpenForm
            isOpenForm={true}
            hasProgramInterestedField={pageUrl === 'CAMPAIGN'}
            wrapperClassName={'flex justify-center'}
            shouldShowAffiliatePagePopup={shouldShowAffiliatePagePopup}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomHeroSection;
