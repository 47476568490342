import React, { useContext, useEffect, useState } from 'react';
import SEO from '@components/Common/SEO';
import useResizer from '@components/extra/useResizer';
import FooterV2 from '@components/FooterV2';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import EventList from '@components/Masterclass/EventList';
import CrioFeedback from '@components/Masterclass/PostRegisterCrioFeedback';
import PostRegisterHero from '@components/Masterclass/PostRegisterHero';
import RequestCallback from '@components/Masterclass/RequestCallback';
import WhatsappGroupDetails from '@components/Masterclass/WhatsappGroupDetails';
import NavigationV2 from '@components/NavigationV2';
import ScrollButtonComponent from '@components/ScrollButtonComponent';
import { LEAD_GEN_FLOATING_CTA } from '@src/constants/LeadGenerationConstants';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import useMasterclassPostRegisterSeo from '@src/hooks/Masterclass/useMasterclassPostRegisterSeo';
import useRegisterMetadata from '@src/hooks/Masterclass/useRegisterMetadata';
import {
  checkUserRegistration,
  redirectToRegistration,
} from '@src/utils/Masterclass';
import CouponDetails from '@components/Masterclass/WhatsappGroupDetails/CouponDetails';

export default function MasterclassPostRegister() {
  // Loading state
  const [isLoading, setIsLoading] = useState(true);

  // Hooks
  const isMobile = useResizer();
  const state = useContext(GlobalStateContext);
  const { getPostRegisterationPageSeo } = useMasterclassPostRegisterSeo();
  const {
    getCurrentProgramUpcomingEvents,
    getEventSlug,
    getCouponDetails,
    getCurrentProgram,
  } = useRegisterMetadata();

  const currentProgram = getCurrentProgram() || 'Software Development';

  // Data
  const events = getCurrentProgramUpcomingEvents() || [];
  const eventSlug = getEventSlug();
  const couponDetails = getCouponDetails();

  const seoData = getPostRegisterationPageSeo();
  const isUserRegistered = checkUserRegistration(state, eventSlug);

  useEffect(() => {
    // Add a small delay to ensure smooth transition
    const redirectTimer = setTimeout(() => {
      if (!isUserRegistered) {
        redirectToRegistration(false, eventSlug);
      } else {
        setIsLoading(false);
      }
    }, 100);

    return () => clearTimeout(redirectTimer);
  }, [eventSlug, isUserRegistered]);

  if (isLoading) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-white">
        <div className="h-10 w-10 animate-spin rounded-full border-4 border-v5-green-200 border-t-transparent"></div>
      </div>
    );
  }

  // Only render content if user is registered
  if (!isUserRegistered) {
    return null;
  }

  return (
    <>
      <SEO
        title={seoData?.title}
        description={seoData.metaDescription}
        meta={seoData.meta}
        canonicalUrl={seoData.canonicalUrl}
      >
        {seoData.script}
      </SEO>

      <ScrollButtonComponent
        component={
          <LeadGenerationButton
            type="Register"
            text="Register Now"
            onClick={() => {
              document.body.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }}
            buttonLocation={LEAD_GEN_FLOATING_CTA}
          />
        }
        startAt={600}
      />

      <NavigationV2 hideCTA hideTopStrip />
      <PostRegisterHero />
      <CouponDetails
        couponDetails={couponDetails}
        currentProgram={currentProgram}
      />
      <WhatsappGroupDetails />
      <RequestCallback />
      <CrioFeedback />

      <EventList
        events={events}
        displayType={isMobile ? 'swiper' : 'grid'}
        shouldShowSection={events.length > 0}
        title="Explore More Events"
        wrapperClassName="pt-10"
      />

      <FooterV2 />
    </>
  );
}
